import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { markExperienceAsVisited, getExperienceInfo } from "./store";
import Layout from "./Layout";
import Spinner from "./Spinner";

const IFramedExperience = ({ experience, hTitle, hContent, hUrl }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const handleLoadEvent = () => {
            setLoaded(true);
        };
        window.addEventListener("listen-for-loaded", handleLoadEvent);
        return () => {
            window.removeEventListener("listen-for-loaded", handleLoadEvent);
        };
    }, []);

    const { name, src } = getExperienceInfo(experience);

    markExperienceAsVisited(name);

    return (
        <Layout>
            <Helmet>
                <title>{`${hTitle}`} - AR DigiTrail</title>
                <meta
                    name="description"
                    content={`${hContent} for Glasgow Science Centre - Augmented Reality experiences`}
                />
                <link rel="canonical" href={`/${hUrl}`} />
            </Helmet>
            {!loaded && <Spinner />}
            <iframe
                id="experienceIframe"
                src={src}
                style={{
                    border: "none",
                    height: "92vh",
                    width: "100%",
                    display: "none"
                }}
                title={name}
                width="100%"
            />
        </Layout>
    );
};

export default IFramedExperience;
